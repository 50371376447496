@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500,600,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  display: flex;
  width: 100%;
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  flex: 1;
}

ul {
  outline: none;
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: #f8f9fa; /*Equivalent to colors.palette.background*/
  overflow: hidden;
}

/* 
  This next line was implemented because elfsight 
  widget was breaking the site,  don't modify it.
*/
div.eapps-widget {
  position: absolute !important;
}
